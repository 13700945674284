@import './_variables';
@import './_mixins';

@for $i from 0 through $opacity-range {
  .hrv-loyalty {
    &-opacity-#{$i} {
      opacity: calc(#{$i} / 10) !important;
    }
  }
}

@include breakpoint(mobile) {
  @for $i from 0 through $opacity-range {
    .hrv-loyalty-sm {
      &-opacity-#{$i} {
        opacity: calc(#{$i} / 10) !important;
      }
    }
  }
}

@include breakpoint(tablet) {
  @for $i from 0 through $opacity-range {
    .hrv-loyalty-md {
      &-opacity-#{$i} {
        opacity: calc(#{$i} / 10) !important;
      }
    }
  }
}

@include breakpoint(bigTablet) {
  @for $i from 0 through $opacity-range {
    .hrv-loyalty-lg {
      &-opacity-#{$i} {
        opacity: calc(#{$i} / 10) !important;
      }
    }
  }
}

@include breakpoint(desktop) {
  @for $i from 0 through $opacity-range {
    .hrv-loyalty-xl {
      &-opacity-#{$i} {
        opacity: calc(#{$i} / 10) !important;
      }
    }
  }
}

@include breakpoint(bigscreen) {
  @for $i from 0 through $opacity-range {
    .hrv-loyalty-2xl {
      &-opacity-#{$i} {
        opacity: calc(#{$i} / 10) !important;
      }
    }
  }
}