.hrv-pagination {
  align-items: inherit !important;
}
.hrv-table thead th {
  color: #252525 !important;
}
.has-error {
  border-color: #f5222d !important;
}

.hrv-fileupload--container {
  background: none !important;
  padding: 0 !important;
}
.hrv-fileupload--box {
  min-height: 104px;
}
.hrv-input-radio-horizon .hrv-next-input-radio + .hrv-next-input-radio {
  margin-top: 0;
}
.hrv-switch-disabled,
.hrv-switch-disabled .hrv-switch:after {
  cursor: not-allowed !important;
}
.hrv-daterangepicker .hrv-daterangepicker--time {
  text-align: left;
}
.hrv-select-selection__rendered {
  padding: 9.2px 0 !important;
}
.hrv-table {
  white-space: normal !important;
}
@keyframes scrollUpModal {
  from {
    bottom: -100%;
  }
  to {
    bottom: 0;
  }
}
@keyframes visibaleBgModal {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.hrv-btn {
  min-width: 0px !important;
}
@media (max-width: 767px) {
  .hrv-tta-table-wrapper {
    background: none;
    .hrv-table-container {
      &::-webkit-scrollbar {
        height: 4px;
        border-radius: 2px;
      }
      &::-webkit-scrollbar-track {
        background: #fff;
      }
      &::-webkit-scrollbar-thumb {
        background: #e0e0e0;
      }
      td {
        &:nth-child(1) {
          min-width: 90px;
        }
        &:nth-child(2) {
          min-width: 245px;
        }
        &:nth-child(3) {
          min-width: 320px;
        }
        &:nth-child(4) {
          min-width: 140px;
        }
        &:nth-child(5) {
          min-width: 125px;
        }
        &:nth-child(6) {
          min-width: 200px;
        }
      }
      tr:last-child td {
        box-shadow: none;
      }
    }
    .hrv-pagination-dropdown {
      height: 40px;
      .hrv-pagination-dropdown--text {
        display: none;
      }
    }
  }
  .rc-dialog-mask {
    animation: visibaleBgModal 0.3s ease-in-out forwards;
  }
  .rc-dialog {
    position: fixed !important;
    z-index: 1051;
    left: 0;
    right: 0;
    margin: 0px !important;
    animation: scrollUpModal 0.3s ease-in-out forwards;
    &.hrv-tta-mt-100 {
      margin-top: auto !important;
    }
    .rc-dialog-content {
      margin-top: auto;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      overflow: hidden;
    }
  }
  .daterangepicker {
    width: calc(100% - 40px) !important;
  }
  .hrv-btn {
    width: 100%;
    margin-top: 8px;
  }
  .hrv-pagination--listpage {
    padding-left: 0px;
  }
  .daterangepicker .drp-buttons .drp-selected {
    padding-bottom: 8px;
  }
}
.hrv-btn.hrv-btn-default {
  box-shadow: none !important;
  color: #3d4f6e !important;

  &:hover {
    opacity: 0.7;
  }
}

.hrv-tta-variants {
  .rc-dialog-header {
    text-align: center !important;
    font-weight: 600;
    font-size: 16px;
  }
  .rc-dialog-body {
    padding: 0 !important;
    padding-bottom: 36px !important;
  }
}

.hrv-tta-table-th-checkbox {
  width: 3%;
}

.hrv-tta-custom-product-status-table {
  tbody td {
    box-shadow: unset;
    border-bottom: 1px solid #e5e7eb !important;

    &.no-border-b {
      border-bottom: unset !important;
    }
  }

  thead tr th {
    padding: 0;
    border-bottom: 1px solid #e5e7eb !important;
  }

  tbody tr td {
    padding: 0;
  }

  thead th:first-child,
  tbody td:first-child,
  tfoot td:first-child {
    padding: 0;
  }

  thead th:last-child,
  tbody td:last-child,
  tfoot td:last-child {
    padding: 0;
  }

  tbody td > div {
    padding-right: 16px;
  }

  thead th > * {
    padding-right: 16px;
  }

  thead th:first-child {
    padding: 0 18px;
  }

  thead th:last-child {
    padding: 0 23px;
  }

  tbody td:first-child {
    padding: 0 18px;
  }

  tbody td:last-child {
    padding: 0 23px;
  }

  thead th > * {
    padding: 14px 0;
    padding-right: 16px;
  }

  tbody td > div {
    padding: 10px 0;
  }

  thead th {
    background-color: #ffffff !important;
  }

  thead th:first-child > * {
    padding: 0;
  }

  thead th:last-child > * {
    padding: 0;
  }

  tbody td:last-child > div {
    padding: 0;
  }

  tbody td:first-child > div {
    padding: 0;
  }

  .hrv-tta-table-selected-item {
    padding: 0 !important;
  }
}

.hrv-tta_custom__primary-alert {
  background-color: #f0f6ff;
  border: 1px solid #91c3fd;
}

.hrv-tta-custom-info-alert {
  background: #f0f6ff;
  border: 1px solid #91c3fd;
  border-radius: 8px;
  margin-bottom: 0;
  font-size: 14px;
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  align-items: center;

  .hrv-alert-message {
    flex: 1;
  }
}
