.hrv-tta-whitebox {
  background: #ffffff;
  box-shadow: 0px 0px 5px rgba(10, 31, 68, 0.21);
  border-radius: 4px;
  padding: 16px 16px 24px 16px;
}

.hrv-tta-whitebox-no-bs {
  background: #ffffff;
  border-radius: 4px;
  padding: 16px 16px 24px 16px;
}

.hrv-tta-table-wrapper {
  background-color: white;
}

.hrv-tta-width-100 {
  width: 100%;
}

.hrv-tta-cursor-pointer {
  cursor: pointer;
}

.hrv-tta-overlay {
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  opacity: 0;
  transition: all 0.3s;
}

.hrv-tta-custom-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 224px;
}

.hrv-tta-checkbox-area-list {
  height: 236px;
  overflow-y: auto;
  @media (max-width: 767px) {
    height: 180px;
  }
  &::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #ffffff;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #d9d9d9;
  }
}
.hrv-tta-custom-mw-800 {
  max-width: 800px;
}
.hrv-tta-custom-mw-600 {
  max-width: 600px;
}
.hrv-tta-custom-shadow-1 {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.hrv-tta-text-capitalize {
  text-transform: capitalize;
}
.hrv-tta-lst-none {
  list-style-type: none;
}
.hrv-tta-text-italic {
  font-style: italic;
}
.hrv-tta-text-ellipsis_2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.hrv-tta-text-nowrap {
  white-space: nowrap;
}

.hrv-tooltip-inner {
  background-color: #212121;
}

.hrv-tta-scrollbar {
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #ffffff;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #d9d9d9;
  }
}

.hrv-tta-cursor-disabled {
  cursor: not-allowed !important;
}

.hrv-tta-mb-150 {
  margin-bottom: 150px;
}
