@import "./_mixins";
@import "./_variables";

.#{$prefix} {
  &-container  {
    max-width: 1245px;
    margin: auto;
  }
}

@for $i from 1 through 10 {
  .#{$prefix} {
    &-w-#{$i}0 {
      width: calc($i * 10%)!important;
    }
  }
  .#{$prefix} {
    &-h-#{$i}0 {
      height: calc($i * 10vh)!important;
    }
  }
}
