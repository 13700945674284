@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700);

// PREFIX
$prefix: hrv-tta;

// FONT_FAMILY
$font-family-primary: "Roboto", Arial !default;

// COLORS
$white: #fff;

$black-1: #202223;
$black-2: #202124;
$black-3: #0a1f44;
$black-4: #212121;
$black-5: #424242;

$gray-1: #808080;
$gray-2: #d9d9d9;
$gray-3: #595959;
$gray-4: #d9d9d9;
$gray-5: #616161;
$gray-6: #333333;
$gray-7: #6d7175;
$gray-8: #f3f3f3;
$gray-9: #4b5563;
$gray-10: #e5e7eb;
$gray-11: #9ca3b0;

$blue-1: #042659;
$blue-2: #2962ff;
$blue-3: #1f58eb;
$blue-4: #0b3d88;

$red-1: #ee3333;

// BREAKPOINTS
$bigscreen: 1400px; //2xl
$desktop: 1200px; //xl
$bigTablet: 992px; //lg
$tablet: 769px; //md
$mobile: 438px; //sm

// GUTTER
$gutter: 0.75rem; //đổi đơn vị rem sang px ta được gutter-px
$gutter-px: 12px;
$gutter-row: 1.5rem;

// SPACINGS
$spacing: 1px;

// Effect
$opacity-range: 80;

// COLORFUL
$colors: (
  "gray-1": $gray-1,
  "gray-2": $gray-2,
  "gray-3": $gray-3,
  "gray-4": $gray-4,
  "gray-5": $gray-5,
  "gray-6": $gray-6,
  "gray-7": $gray-7,
  "black-1": $black-1,
  "black-4": $black-4,
  "black-5": $black-5,
  "blue-1": $blue-1,
  "blue-2": $blue-2,
  "blue-3": $blue-3,
  "blue-4": $blue-4,
  "red-1": $red-1,
  "white": $white,
  "gray-8": $gray-8,
  "gray-9": $gray-9,
  "gray-10": $gray-10,
  "gray-11": $gray-11,
);
